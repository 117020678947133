import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import Store from "store";
import Axios from "axios";
import {reAuth} from "../../../../utils/utils";

class SingleShip extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.entryId,
            token: Store.get('accessToken').token,
            data: null
        };
    }

    componentDidMount() {
        let config = {};

        config.headers = {
            'Authorization': 'Bearer ' + this.state.token
        };

        Axios.get(process.env.REACT_APP_API_BASE + '/business/ship/'+this.state.id, config).then(response => {
            console.log(response);
            if (response.status === 200) {
                this.setState({
                    data: response.data
                });
            } else {
                throw new Error('Smt went wrong with fetching orders');
            }
        }).catch(err => {
            console.log(err);
            if (err.response.status === 401) {
                console.log('attempting reauth');
                reAuth();
            }
        });
    }

    deleteShip = () => {
        // eslint-disable-next-line no-restricted-globals
        const deleteShip = confirm("Ali ste prepričani da želite izbrisati kapetana?");
        /* eslint-enable */
        if (deleteShip) {
            let config = {};
            config.headers = {
                'Authorization': 'Bearer ' + this.state.token
            };
            Axios.delete(process.env.REACT_APP_API_BASE + '/business/ship/' + this.state.id, config)
                .then(response => {
                    if(response.status === 200) {
                        this.props.history.push('/dashboard');
                    }
                })

        }
    }

    render() {
        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <div className="d-flex justify-content-between align-items-center">
                            <h1 className={"mt-1 mb-3"}>Predogled plovila:</h1>
                            <div>
                                <a class={"btn btn-secondary me-3"} onClick={this.deleteShip}>Izbriši plovilo</a>
                                <Link class={"btn btn-secondary me-3"} to={"/dashboard/ships/ship/edit/" + this.state.id}>Uredi plovilo</Link>
                                <Link class={"btn btn-secondary"} to={"/dashboard/ships/ship/" + this.state.id + "/relations"}>Določi relacije</Link>
                            </div>
                        </div>

                        <p className={"mt-2"}>Registracijska številka: {this.state.data ? this.state.data.ship_registration : null}</p>
                        <p className={"mt-2"}>Veljavnost registracije: {this.state.data ? this.state.data.registration_until : null}</p>
                        <p className={"mt-2"}>Ime plovila: {this.state.data ? this.state.data.ship_name : null}</p>
                        <p className={"mt-2"}>Tip plovila: {this.state.data ? this.state.data.type : null}</p>
                        <p className={"mt-2"}>Število potnikov: {this.state.data ? this.state.data.capacity : null}</p>
                        <p className={"mt-2"}>Dolžina: {this.state.data ? this.state.data.length_m : null}</p>
                        <p className={"mt-2"}>Številka zavarovanja: {this.state.data ? this.state.data.insurance_number : null}</p>
                        <p className={"mt-2"}>Datum veljavnosti zavarovanja: {this.state.data ? this.state.data.insurance_until : null}</p>
                        <p className={"mt-2"}>Storitve: {this.state.data ? this.state.data.features : null}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-7">

                    </div>
                </div>
            </div>
        );
    }
}

SingleShip.propTypes = {};

export default SingleShip;
