import React from "react";
import Store from "store";
import Axios from "axios";
import {reAuth} from "../../../../utils/utils";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {Accordion, Card, Button} from "react-bootstrap";
import Orders from "../Orders";

class AllOrders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.entryId,
            token: Store.get('accessToken').token,
            data: null,
            orderCount: null
        };

        this.updateCounter = this.updateCounter.bind(this);
    }

    updateCounter(count) {
        this.setState({
            orderCount: count
        })
    }

    componentDidMount() {
        let config = {};

        config.headers = {
            'Authorization': 'Bearer ' + this.state.token
        };

        Axios.get(process.env.REACT_APP_API_BASE + '/orders/'+this.state.id, config).then(response => {
            console.log(response);
            if (response.status === 200) {
                this.setState({
                    data: response.data.order
                });
            } else {
                throw new Error('Smt went wrong with fetching orders');
            }
        }).catch(err => {
            console.log(err);
            if (err.response.status === 401) {
                console.log('attempting reauth');
                reAuth();
            }
        });
    }

    render() {
        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <h1 className={"mt-1"}>Orders & Inspections Overview</h1>
                    </div>

                    <div className="col-12 col-md-4 d-flex justify-content-end align-items-center">
                        <Link to={"/dashboard/orders/add-new"} className="btn btn-primary">Start new order</Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <section>
                            <Accordion>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                        Filter Data
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>Filters placeholder</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </section>

                        <section className="mt-3">
                            <div className="card">
                                <div className="card-body">
                                    Sort data by
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className={"d-flex align-items-center"}>
                                <h2 className={"mb-0"}>Open orders</h2>
                                <span className="circle-count ms-3">{this.state.orderCount}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12">
                        <Orders updateCounter={this.updateCounter}/>
                    </div>
                </div>
            </div>
        );
    }
}

AllOrders.propTypes = {};

export default AllOrders;
