import React from "react";
import {Link} from "react-router-dom";
import {getAdmins} from "../../../../utils/utils";
import Axios from "axios";

import Store from "store";

class AddNewCaptain extends React.Component {
    constructor(props) {
        super(props);

        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < 20; i++ ) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }

        this.state = {
            token: Store.get('accessToken').token,
            'firstName': '',
            'lastName': '',
            'email': '',
            'password': result,
            'address': '',
            'phone_number': '',
            'proof_vhf': '',
            'proof_ship_leader': ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);


    }

    handleSubmit(e) {
        e.preventDefault();

        console.log(this.state.id);
        Axios.post(process.env.REACT_APP_API_BASE + '/business/captain', {
            'firstName': this.state.firstName,
            'lastName': this.state.lastName,
            'email': this.state.email,
            'password': this.state.password,
            'address': this.state.address,
            'phone_number': this.state.phone_number,
            'proof_ship_leader': this.state.proof_ship_leader,
            'proof_vhf': this.state.proof_vhf
        }   , {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        })
        .then(response => {
            console.log(response);
            if(response.status === 200) {
                this.props.history.push('/dashboard');
            }
        })
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        return (
            <div className="container spacing-top" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard"} className={"btn-min"}>
                            <span className="ml-1">Preglej plovila in kapetane</span>
                        </Link>
                        <h1 className={"mt-1"}>Dodaj novega kapetana</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-8">
                        <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="owner">Ime:</label>
                                <input type="text" placeholder="Janez" className={"form-control"} name={"firstName"} id={"firstName"} value={this.state.firstName} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Priimek:</label>
                                <input type="text" placeholder="Novak" className={"form-control"} name={"lastName"} id={"lastName"} value={this.state.lastName} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Naslov:</label>
                                <input type="text" placeholder="Ulica 15" className={"form-control"} name={"address"} id={"address"} value={this.state.address} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Telefon:</label>
                                <input type="text" placeholder="051 123 456" className={"form-control"} name={"phone_number"} id={"phone_number"} value={this.state.phone_number} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">E-Naslov:</label>
                                <input type="text" placeholder="janez.novak@gmail.com" className={"form-control"} name={"email"} id={"email"} value={this.state.email} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Dokazilo za voditelja čolna:</label>
                                <input type="text" className={"form-control"} name={"proof_ship_leader"} id={"proof_ship_leader"} value={this.state.proof_ship_leader} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Dokazilo VHF:</label>
                                <input type="password" className={"form-control"} name={"proof_vhf"} id={"proof_vhf"} value={this.state.proof_vhf} onChange={this.handleChange} />
                            </div>
                            {/*<div className="form-group">
                                <label htmlFor="owner">Geslo:</label>
                                <input type="password" className={"form-control"} name={"password"} id={"password"} value={this.state.password} onChange={this.handleChange} required />
                            </div>*/}

                            <div className="row mt-3">
                                <div className="col-12">
                                    <button type={"submit"} className={"btn btn-secondary"}>Dodaj kapetana</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

AddNewCaptain.propTypes = {};

export default AddNewCaptain;
