import React from "react";
import "./Home.scss";
import "../../custom.scss";
import wayv_admin_logo from '../../assets/images/wayv-login-logo.png';

import Login from "./Login/Login";


class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className="home-bg d-flex align-items-center">

                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 mx-md-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <img src={wayv_admin_logo} alt=""/>
                                        <h1 className="logo">Business</h1>
                                    </div>
                                    <div className="mt-5">
                                        <Login/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

Home.propTypes = {};

export default Home;
