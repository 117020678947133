import React from "react";
import Axios from "axios";
import Store from "store";
import {reAuth} from "../../../utils/utils";
import Order from "./Order/Order";

class Orders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            items: null
        };
    }

    componentDidMount() {
        let config = {};

        config.headers = {
            'Authorization': 'Bearer ' + this.state.token
        };

        Axios.get(process.env.REACT_APP_API_BASE + '/orders', config).then(response => {
            console.log(response);
            if (response.status === 200) {
                this.setState({
                    items: response.data.orders
                }, () => {
                    if (this.props.updateCounter) {
                        this.props.updateCounter(response.data.orders.length);
                    }
                });
            } else {
                throw new Error('Smt went wrong with fetching orders');
            }
        }).catch(err => {
            console.log(err);
            if (err.response.status === 401) {
                console.log('attempting reauth');
                reAuth();
            }
        });
    }

    renderItems() {
        if (this.state.items) {
            return this.state.items.map(item => {
                return <Order data={item} />
            })
        }
    }

    render() {

        return (
            <div className={"layout-grid-3"}>
                {this.renderItems()}
            </div>
        );
    }
}

Orders.propTypes = {};

export default Orders;
