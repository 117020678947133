import React from "react";
import {Link} from "react-router-dom";
import './Ship.scss';



class Ship extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

    }

    render() {
        return (
            <Link to={"/dashboard/ships/ship/" + this.props.data.id} className={"card text-decoration-none"}>
                <div className="card-body">
                    <div className={"d-flex flex-row"}>
                        <div className="ship-data">
                            <div>
                                <strong className="text-primary">Registracijska številka: </strong>
                                <span>{this.props.data.ship_registration}</span>
                            </div>
                            <div>
                                <strong className="text-primary">Veljavnost registracije: </strong>
                                <span>{this.props.data.registration_until}</span>
                            </div>
                            <div>
                                <strong className="text-primary">Ime plovila: </strong>
                                <span>{this.props.data.ship_name}</span>
                            </div>
                            <div>
                                <strong className="text-primary">Tip plovila: </strong>
                                <span>{this.props.data.type}</span>
                            </div>
                            <div>
                                <strong className="text-primary">Število mest: </strong>
                                <span>{this.props.data.capacity}</span>
                            </div>
                            <div>
                                <strong className="text-primary">Datum veljavnosti zavarovanja: </strong>
                                <span>{this.props.data.insurance_until}</span>
                            </div>
                        </div>
                        <div className="ship-image">
                            <img className="image" src={this.props.data.image} alt=""/>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

Ship.propTypes = {};

export default Ship;
