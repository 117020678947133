import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";

import Store from "store";


import Dashboard from "./Pages/Dashboard/Dashboard";
import Home from "./Pages/Home/Home";
import PrivateRoute from "./PrivateRoute";
import {isLogin} from "./utils/utils";




class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      user: {

      },
      accessToken: null
    };

    this.handleSuccessfulLogin = this.handleSuccessfulLogin.bind(this);
  }



  handleSuccessfulLogin(data, cb) {

    this.setState({
      loggedIn: true,
      user: data.user,
      accessToken: data['access_token']
    });



    console.log('this is registration data', data);

    Store.set('user', data.user);
    Store.set('accessToken', data['access_token']);
    Store.set('addresses', data.address);


    cb();
  }

  componentDidMount() {
    const user = Store.get('user'),
        accessToken = Store.get('accessToken');

    this.setState({
      loggedIn: (user) ? true : false,
      user: user,
      accessToken: accessToken
    });
  }

  render() {
    return (
        <div className={"app"}>
          <Router>
            <Switch>


              <PrivateRoute path="/dashboard*" component={Dashboard} exact />

              {/*Component should be "FeDashboard"... update FePrivateRoute to redirect to a front end "login" form*/}
              {/*<FePrivateRoute path="/fe/sr/:entryId" component={FeSigningRequest} exact />*/}
              {/*<FePrivateRoute path="/fe/sr/:entryId/document-instance/:instanceId" component={FeDocumentInstance} exact />*/}

              {/*<FePrivateRoute path="/fe/es/:entryId" type={"es"} component={FeExpenseSheet} exact />*/}


              <Route path="/" render={props => (
                  !isLogin() ?
                      <Home
                          {...props}
                      />
                      : <Redirect to="/dashboard" />
              )}
              />

            </Switch>
          </Router>
        </div>
    );
  }
}

App.propTypes = {};

export default App;
