import React from "react";
import {Link} from "react-router-dom";
import './Order.scss';


class Order extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

    }

    render() {
        return (
            <Link to={"/dashboard/orders/order/" + this.props.data.id} className={"card text-decoration-none"}>
                <div className="card-body">
                    <div>
                        <strong className="text-primary">Name: </strong>
                        <span>{this.props.data.name}</span>
                    </div>
                    <div className={"my-2"}>
                        <span>This is a test</span>
                    </div>
                    <div>
                        <strong className="text-primary">Units: </strong>
                        <span>{this.props.data.email}</span>
                    </div>
                </div>
            </Link>
        );
    }
}

Order.propTypes = {};

export default Order;
