import React from "react";
import {
    Link,
    Route,
    withRouter
} from "react-router-dom";
import Store from "store";


import Navigation from "./Navigation/Navigation";
import AccountSettings from "./AccountSettings/AccountSettings";
import EditUser from "./Users/EditUser/EditUser";
import AddNewUser from "./Users/AddNewUser/AddNewUser";
import Users from "./Users/Users";
import Captains from "./Captains/Captains";
import AddNewShip from "./Ships/AddNewShip/AddNewShip";
import Ships from "./Ships/Ships"
import AddNewShipRelations from "./Ships/AddNewShipRelations/AddNewShipRelations";
import SingleShip from "./Ships/SingleShip/SingleShip";
import AddNewCaptain from "./Captains/AddNewCaptain/AddNewCaptain";
import EditCaptain from "./Captains/EditCaptain/EditCaptain";
import SingleCaptain from "./Captains/SingleCaptain/SingleCaptain";

import AllOrders from "./Orders/AllOrders/AllOrders";
import EditShip from "./Ships/EditShip/EditShip";



class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: Store.get('user'),
        };
    }

    /*userUpdate() {
        this.setState({
            user: Store.get('user')
        })
    }*/

    companyName() {
        if (this.state.user) {
            return (
                <div>
                    <h4 className="mb-0">Dobrodošli nazaj, {this.state.user.name}</h4>
                </div>
            );
        }
    }

    render() {
        return (


            <div>

                <Navigation user={this.state.user}/>


                <Route path="/dashboard/users" exact component={Users}/>
                <Route path="/dashboard/users/add-new" exact component={AddNewUser}/>
                <Route path="/dashboard/users/edit/:entryId" exact component={() => <EditUser userUpdate={this.userUpdate} />}/>


                <Route path="/dashboard/captains/add-new" exact component={AddNewCaptain} />
                <Route path="/dashboard/captains/captain/:entryId" exact component={SingleCaptain} />
                <Route path="/dashboard/captains/captain/edit/:entryId" exact component={EditCaptain} />

                <Route path="/dashboard/ships/ship/new/add-new" exact component={AddNewShip} />
                <Route path="/dashboard/ships/ship/:entryId" exact component={SingleShip} />
                <Route path="/dashboard/ships/ship/edit/:entryId" exact component={EditShip} />

                <Route path="/dashboard/ships/ship/:entryId/relations" exact component={AddNewShipRelations} />


                <Route path="/dashboard" exact>
                    <div id="main" className={"pt-0"}>
                        <section className={"bg-white"}>
                            <div className="container py-5">
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        {this.companyName()}
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className={"pt-5"}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className={"d-flex align-items-center"}>
                                                <h2 className={"mb-0"}>Seznam kapetanov</h2>
                                            </div>
                                            <div>
                                                <Link class={"btn btn-secondary"} to={"/dashboard/captains/add-new"}>Dodaj kapetana</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12">
                                        <Captains />
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className={"d-flex align-items-center"}>
                                                <h2 className={"mb-0"}>Seznam plovil</h2>
                                            </div>
                                            <div>
                                                <Link class={"btn btn-secondary"} to={"/dashboard/ships/ship/new/add-new"}>Dodaj plovilo</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12">
                                        <Ships />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </Route>
            </div>
        );
    }

}

export default withRouter(Dashboard);
