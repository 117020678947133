import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import Store from "store";
import Axios from "axios";
import {reAuth} from "../../../../utils/utils";
import {config} from "@fortawesome/fontawesome-svg-core";

class EditCaptain extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.entryId,
            token: Store.get('accessToken').token,
            data: {
                licence_number: '',
                phone_number: '',
                address: '',
                proof_ship_leader: '',
                proof_vhf: ''
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        let config = {};

        config.headers = {
            'Authorization': 'Bearer ' + this.state.token
        };

        Axios.get(process.env.REACT_APP_API_BASE + '/business/captain/'+this.state.id, config).then(response => {
            console.log(response);
            if (response.status === 200) {
                this.setState({
                    data: response.data.profile
                });
            } else {
                throw new Error('Smt went wrong with fetching orders');
            }
        }).catch(err => {
            console.log(err);
            if (err.response.status === 401) {
                console.log('attempting reauth');
                reAuth();
            }
        });
    }

    handleChange(e) {
        console.log(e.target.name)
        let data = {...this.state.data};
        data[e.target.name] = e.target.value;
        console.log(data);
        this.setState({
            data: data
        })
    }

    handleSubmit(e) {
        e.preventDefault();

        Axios.post(process.env.REACT_APP_API_BASE + '/business/captain/' + this.state.id, {
            'address': this.state.data.address,
            'proof_ship_leader': this.state.data.proof_ship_leader,
            'proof_vhf': this.state.data.proof_vhf,
            'licence_number': this.state.data.licence_number,
            'phone_number': this.state.data.phone_number,
        }, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        }).then(response => {
            if (response.status === 200) {
                this.props.history.push('/dashboard/captains/captain/' + this.state.id);
            } else {
                throw new Error('Smt went wrong with fetching orders');
            }
        }).catch(err => {
            console.log(err);
            if (err.response.status === 401) {
                console.log('attempting reauth');
                reAuth();
            }
        });
    }

    render() {
        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard"} className={"btn-min"}>
                            <span className="ml-1">Preglej plovila in kapetane</span>
                        </Link>
                        <h1 className={"mt-1"}>Uredi kapetana</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-8">
                        <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="owner">Naslov:</label>
                                <input type="text" className={"form-control"} name={"address"} id={"address"} value={this.state.data.address} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Telefonska številka:</label>
                                <input type="text" className={"form-control"} name={"phone_number"} id={"phone_number"} value={this.state.data.phone_number} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Dokazilo za voditelja čolna:</label>
                                <input type="text" className={"form-control"} name={"proof_ship_leader"} id={"proof_ship_leader"} value={this.state.data.proof_ship_leader} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Dokazilo VHF:</label>
                                <input type="text" className={"form-control"} name={"proof_vhf"} id={"proof_vhf"} value={this.state.data.proof_vhf} onChange={this.handleChange} />
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <button type={"submit"} className={"btn btn-primary"}>Shrani spremembe</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

EditCaptain.propTypes = {};

export default EditCaptain;
