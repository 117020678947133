import React from "react";
import styles from "./Navigation.module.scss";
import "./Navigation.scss";
import wayv_admin_logo from '../../../assets/images/wayv-login-logo.png';

import {
    BrowserRouter as Router, NavLink,
    withRouter
} from "react-router-dom";

import {logout} from '../../../utils/utils';
import Dropdown from "react-bootstrap/Dropdown";

import Store from "store";

class Navigation extends React.Component {

    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
    }

    logout() {
        /* eslint-disable */
        const toLogout = confirm("Are you sure to logout ?");
        /* eslint-enable */
        if (toLogout) {
            logout();
            this.props.history.push('/');
        }
    }

    homeHandler = () => {
        this.props.history.push('/dashboard');
    }


    render() {
        return (
            <div className={styles.navbar}>
                <nav className="navbar navbar-expand-lg d-flex justify-content-between w-100 px-5">
                    <div onClick={this.homeHandler} className="logo me-auto d-flex flex-row">
                        <img className="wayv_logo" src={wayv_admin_logo} alt=""/>
                        <h2 className={"mb-0 ms-4"}>Business</h2>
                    </div>
                    <div className={"d-flex"}>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarText"
                            aria-controls="navbarText"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        {/*<div className={"me-auto"}>
                            <input placeholder={"Enter search input"} type="text" className="form-control" id={"ares-search"} name={"ares-search"}/>
                        </div>*/}

                        <div className="collapse navbar-collapse" id="navbarText">
                            {/*<div className="me-4">
                                <Router>
                                    <ul className="navbar-nav">
                                        <li className="nav-item me-3">
                                            <NavLink to={"/dashboard/system-settings"}>System settings</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to={"/dashboard/help-support"}>Help & Support</NavLink>
                                        </li>
                                    </ul>
                                </Router>
                            </div>*/}
                            <Dropdown alignRight>
                                <Dropdown.Toggle className={"d-flex align-items-center"} id="user-dropdown">
                                    <span className={styles.name + " navbar-text"}>{this.props.user ? this.props.user.name : null}</span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <div className="dropdown-item">
                                        <NavLink to={this.props.user ? "/dashboard/users/edit/"+this.props.user.id : null} activeClassName="selected">Nastavitve</NavLink>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Router>
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                    <span
                                        className={styles.logout + " nav-link cursor-pointer"}
                                        onClick={this.logout}>
                                        Odjava
                                    </span>
                                    </li>
                                </ul>
                            </Router>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}


export default withRouter(Navigation);
