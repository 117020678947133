import React from "react";
import Axios from "axios";
import Store from "store";
import {reAuth} from "../../../utils/utils";
import Captain from "./Captain/Captain";

class Captains extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            items: null
        };
    }

    componentDidMount() {
        let config = {};

        config.headers = {
            'Authorization': 'Bearer ' + this.state.token
        };

        Axios.get(process.env.REACT_APP_API_BASE + '/business/captain', config).then(response => {
            console.log(response);
            if (response.status === 200) {
                this.setState({
                    items: response.data
                });
            } else {
                throw new Error('Smt went wrong with fetching captains');
            }
        })
    }

    renderItems() {
        if (this.state.items) {
            return this.state.items.map(item => {
                return <Captain data={item} />
            })
        }
    }

    render() {

        return (
            <div className={"layout-grid-3"}>
                {this.renderItems()}
            </div>
        );
    }
}

Captains.propTypes = {};

export default Captains;
