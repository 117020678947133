import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import Store from "store";
import Axios from "axios";
import {reAuth} from "../../../../utils/utils";
import {config} from "@fortawesome/fontawesome-svg-core";
import Select from "react-select";

class EditShip extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.entryId,
            token: Store.get('accessToken').token,
            data: {
                'ship_name': '',
                'ship_registration': '',
                'registration_until': '',
                'insurance_number': '',
                'insurance_until': '',
                'length_m': '',
                'type': '',
                'capacity': '',
            },
            'features': [],
            'options': [
                { value: 'WC', label: 'WC' },
                { value: 'Tuš', label: 'Tuš' },
                { value: 'Senčilo', label: 'Senčilo' },
                { value: 'Hladilnik', label: 'Hladilnik' },
                { value: 'Hrana', label: 'Hrana' },
                { value: 'Pijača', label: 'Pijača' },
                { value: 'Minibar', label: 'Minibar' },
                { value: 'Prtljaga', label: 'Prtljaga' },
                { value: 'Tenda', label: 'Tenda' },
                { value: 'Kuhinja', label: 'Kuhinja' }
            ],
            image: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        let config = {};

        config.headers = {
            'Authorization': 'Bearer ' + this.state.token
        };

        Axios.get(process.env.REACT_APP_API_BASE + '/business/ship/'+this.state.id, config).then(response => {
            console.log(response);
            if (response.status === 200) {
                this.setState({
                    data: response.data
                });

                let data = {...this.state.data};
                data.features = [];
                this.setState({
                    data: data
                })
            } else {
                throw new Error('Smt went wrong with fetching orders');
            }
        }).catch(err => {
            console.log(err);
            if (err.response.status === 401) {
                console.log('attempting reauth');
                reAuth();
            }
        });



    }

    handleChange(e) {
        console.log(e.target.name)
        let data = {...this.state.data};
        data[e.target.name] = e.target.value;
        console.log(data);
        this.setState({
            data: data
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        const data = new FormData();
        data.append('ship_name', this.state.data.ship_name);
        data.append('ship_registration', this.state.data.ship_registration);
        data.append('registration_until', this.state.data.registration_until);
        data.append('insurance_number', this.state.data.insurance_number);
        data.append('insurance_until', this.state.data.insurance_until);
        data.append('length', this.state.data.length);
        data.append('type', this.state.data.type);
        data.append('capacity', this.state.data.capacity);
        data.append('features', this.state.features);
        data.append('image', this.state.image);



        Axios.post(process.env.REACT_APP_API_BASE + '/business/ship/' + this.state.id, data, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        }).then(response => {
            if (response.status === 200) {
                this.props.history.push('/dashboard/ships/ship/' + this.state.id);
            } else {
                throw new Error('Smt went wrong with fetching orders');
            }
        }).catch(err => {
            console.log(err);
            if (err.response.status === 401) {
                console.log('attempting reauth');
                reAuth();
            }
        });
    }

    imageHandleChange = (event) => {
        this.setState({
            image: event.target.files[0]
        })
    }

    optionsChange = (event) => {
        let features = [];
        event.forEach(feature => {
            features.push(feature.value);
        })
        this.setState({
            features: features
        })
    }

    render() {
        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard"} className={"btn-min"}>
                            <span className="ml-1">Preglej plovila in kapetane</span>
                        </Link>
                        <h1 className={"mt-1"}>Uredi plovilo</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-8">
                        <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="owner">Veljavnost registracije: *</label>
                                <input type="text" className={"form-control"} name={"registration_until"} id={"registration_until"} value={this.state.data.registration_until} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Ime plovila:</label>
                                <input type="text" className={"form-control"} name={"ship_name"} id={"ship_name"} value={this.state.data.ship_name} onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Število mest: *</label>
                                <input type="text" className={"form-control"} name={"capacity"} id={"capacity"} value={this.state.data.capacity} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Številka zavarovanja: *</label>
                                <input type="text" className={"form-control"} name={"insurance_number"} id={"insurance_number"} value={this.state.data.insurance_number} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Datum veljavnosti zavarovanja: *</label>
                                <input type="text" className={"form-control"} name={"insurance_until"} id={"insurance_until"} value={this.state.data.insurance_until} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Dodatne storitve:</label>
                                <Select
                                    isMulti
                                    name="colors"
                                    options={this.state.options}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={this.optionsChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="image">Slika plovila:</label>
                                <input type="file" className={"form-control"} name={"image"} id={"image"} onChange={this.imageHandleChange} />
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <button type={"submit"} className={"btn btn-primary"}>Uredi plovilo</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

EditShip.propTypes = {};

export default EditShip;
