import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import Store from "store";
import Axios from "axios";
import {reAuth} from "../../../../utils/utils";

class SingleCaptain extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.entryId,
            token: Store.get('accessToken').token,
            data: null
        };
    }

    componentDidMount() {
        let config = {};

        config.headers = {
            'Authorization': 'Bearer ' + this.state.token
        };

        Axios.get(process.env.REACT_APP_API_BASE + '/business/captain/'+this.state.id, config).then(response => {
            console.log(response);
            if (response.status === 200) {
                console.log('dela');
                this.setState({
                    data: response.data
                });
            } else {
                throw new Error('Smt went wrong with fetching orders');
            }
        }).catch(err => {
            console.log(err);
            if (err.response.status === 401) {
                console.log('attempting reauth');
                reAuth();
            }
        });
    }

    deleteCaptain = () => {
        // eslint-disable-next-line no-restricted-globals
        const deleteCaptain = confirm("Ali ste prepričani da želite izbrisati kapetana?");
        /* eslint-enable */
        if (deleteCaptain) {
            let config = {};
            config.headers = {
                'Authorization': 'Bearer ' + this.state.token
            };
            Axios.delete(process.env.REACT_APP_API_BASE + '/business/captain/' + this.state.id, config)
            .then(response => {
                if(response.status === 200) {
                    this.props.history.push('/dashboard');
                }
            })

        }
    }

    render() {
        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <div className="d-flex justify-content-between align-items-center">
                            <Link to={"/dashboard/"} className={"btn-min"}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                                <span className="ml-1">Preglej plovila in kapetane</span>
                            </Link>
                            <div>
                                <a class={"btn btn-secondary me-2"} onClick={this.deleteCaptain}>Izbriši kapetana</a>
                                <Link class={"btn btn-secondary"} to={"/dashboard/captains/captain/edit/" + this.state.id}>Uredi kapetana</Link>
                            </div>
                        </div>
                        <h1 className={"mt-1 mb-3"}>Predogled kapetana</h1>
                        <p className={"mt-2"}>Ime in priimek: {this.state.data ? this.state.data.name : null}</p>
                        <p className={"mt-2"}>Naslov: {this.state.data ? this.state.data.profile.address : null}</p>
                        <p className={"mt-2"}>Telefon: {this.state.data ? this.state.data.profile.phone_number : null}</p>
                        <p className={"mt-2"}>E-Naslov: {this.state.data ? this.state.data.email : null}</p>
                        <p className={"mt-2"}>Dokazilo za voditelja čolna: {this.state.data ? this.state.data.profile.proof_ship_leader : null}</p>
                        <p className={"mt-2"}>Dokazilo VHF: {this.state.data ? this.state.data.profile.proof_vhf : null}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-7">

                    </div>
                </div>
            </div>
        );
    }
}

SingleCaptain.propTypes = {};

export default SingleCaptain;
