import React from "react";
import {Link} from "react-router-dom";
import {getAdmins} from "../../../../utils/utils";
import Axios from "axios";
import Select from 'react-select';

import Store from "store";

class AddNewShip extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            'ship_name': '',
            'ship_registration': '',
            'registration_until': '',
            'insurance_number': '',
            'insurance_until': '',
            'length': '',
            'type': '',
            'capacity': '',
            'image': '',
            'features': [],
            'options': [
                { value: 'WC', label: 'WC' },
                { value: 'Tuš', label: 'Tuš' },
                { value: 'Senčilo', label: 'Senčilo' },
                { value: 'Hladilnik', label: 'Hladilnik' },
                { value: 'Hrana', label: 'Hrana' },
                { value: 'Pijača', label: 'Pijača' },
                { value: 'Minibar', label: 'Minibar' },
                { value: 'Prtljaga', label: 'Prtljaga' },
                { value: 'Tenda', label: 'Tenda' },
                { value: 'Kuhinja', label: 'Kuhinja' }
            ]
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    imageHandleChange = (event) => {
        this.setState({
            image: event.target.files[0]
        })
    }

    optionsChange = (event) => {
        let features = [];
        event.forEach(feature => {
            features.push(feature.value);
        })
        this.setState({
            features: features
        })
    }

    componentDidMount() {
        getAdmins();
    }

    handleSubmit(e) {
        e.preventDefault();
        const data = new FormData();

        data.append('ship_name', this.state.ship_name);
        data.append('ship_registration', this.state.ship_registration);
        data.append('registration_until', this.state.registration_until);
        data.append('insurance_number', this.state.insurance_number);
        data.append('insurance_until', this.state.insurance_until);
        data.append('length_m', this.state.length);
        data.append('type', this.state.type);
        data.append('capacity', this.state.capacity);
        data.append('features', this.state.features)
        data.append('image', this.state.image);

        Axios.post(process.env.REACT_APP_API_BASE + '/business/ship', data, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        }).then(response => {
            if(response.status === 200) {
                this.props.history.push('/dashboard');
            }
        }).catch(error => {
            console.log(error);
        })
    }

    render() {
        return (
            <div className="container pb-10" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard"} className={"btn-min"}>
                            <span className="ml-1">Preglej plovila in kapetane</span>
                        </Link>
                        <h1 className={"mt-1"}>Dodaj novo plovilo</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-8">
                        <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="owner">Registracijska številka: *</label>
                                <input type="text" className={"form-control"} name={"ship_registration"} id={"ship_registration"} value={this.state.ship_registration} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Veljavnost registracije: *</label>
                                <input type="text" className={"form-control"} name={"registration_until"} id={"registration_until"} value={this.state.registration_until} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Ime plovila:</label>
                                <input type="text" className={"form-control"} name={"ship_name"} id={"ship_name"} value={this.state.ship_name} onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Tip: *</label>
                                <select name="type" id="type" className="form-select" aria-label="Default select example" onChange={this.handleChange} required>
                                    <option selected value="">Izberi</option>
                                    <option value="Čoln">Čoln</option>
                                    <option value="Skuter">Skuter</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Število mest: *</label>
                                <input type="text" className={"form-control"} name={"capacity"} id={"capacity"} value={this.state.capacity} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Dolžina:</label>
                                <input type="text" className={"form-control"} name={"length"} id={"length"} value={this.state.length} onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Številka zavarovanja: *</label>
                                <input type="text" className={"form-control"} name={"insurance_number"} id={"insurance_number"} value={this.state.insurance_number} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="owner">Datum veljavnosti zavarovanja: *</label>
                                <input type="text" className={"form-control"} name={"insurance_until"} id={"insurance_until"} value={this.state.insurance_until} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                            <label htmlFor="owner">Dodatne storitve:</label>
                            <Select
                                isMulti
                                name="colors"
                                options={this.state.options}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={this.optionsChange}
                            />
                            </div>
                            <div className="form-group">
                                <label htmlFor="image">Slika plovila:</label>
                                <input type="file" className={"form-control"} name={"image"} id={"image"} onChange={this.imageHandleChange} />
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <button type={"submit"} className={"btn btn-secondary"}>Dodaj plovilo</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

AddNewShip.propTypes = {};

export default AddNewShip;
