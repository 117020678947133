import Store from "store";
import Axios from "axios";

export const logout = () => {

    Store.remove('user');
    Store.remove('accessToken');
};

export const feLogout = () => {
    Store.remove('accessKey');
};

export const isLogin = () => {
    let jwt = Store.get('accessToken');
    let now = new Date().getTime();

    if (!jwt) return false;

    //If less than 30 minutes
    if (jwt.expiration - now < 1800000) {
        reAuth().then((resp) => {
            return true;
        }).catch(() => {
            return false;
        });
    }
    else if(jwt.expiration - now < 10000) {
        Store.remove('accessToken');
        Store.remove('user');
        return false;
    }
    else {
        return true;
    }
};



//Todo add FE auth
export const isFeAuth = () => {
    let jwt = Store.get('accessKey');
    let now = new Date().getTime();

    if (!jwt) return false;

    //If less than 30 minutes
    if (jwt.expiration - now < 1800000) {
        console.log('should reauth');
        return false;
    }
    else if(jwt.expiration - now < 10000) {
        console.log('should relogin');
        return false;
    }
    else {
        return true;
    }
};

export const getInitials = (string) => {
    if (!string) return;
    var names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};




export const isEmpty = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const makeId = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const reAuth = () => {
    var expiration = new Date().getTime() + 10800000;

    return new Promise((resolve, reject) => {
        Axios.post(process.env.REACT_APP_API_BASE + '/auth/token/refresh', null, {
            headers: {
                'Authorization': Store.get('accessToken').token
            }
        } )
            .then(response => {
                if (response.status === 200) {
                    Store.set('accessToken', {
                        token: response.headers.authorization,
                        expiration : expiration
                    }, () => {
                        resolve(response);
                    });
                }
            })
            .catch(error => {
                reject(error);
            })
    })
};

export const getClient = (id) => {
    if (!id) throw new Error('Missing ID param');

    return new Promise((resolve, reject) => {
        Axios.post(process.env.REACT_APP_API_BASE + '/clients/'+id, null, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        } )
            .then(response => {

                console.log('test');
                // if (response.status === 200) {
                //     Store.set('accessToken', {
                //         token: response.headers.authorization,
                //         expiration : expiration
                //     }, () => {
                //         resolve(response);
                //     });
                // }
            })
            .catch(error => {
                reject(error);
            })
    })
}

export const calculate = (key, data) => {
    if (data) {
        return data.map((item, index) => {
            return item[key] ? item[key] : 0;
        }).reduce(function(curval, newval) { return curval + newval });
    }

};


export const getCurrentUser = (id) => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/admins/'+id, {
            headers: {
                'Authorization': Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                Store.set('user', r.data);
                resolve(r);
            } else {
                reject(r);
            }
        });
    }));
};


export const getAdmins = () => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/admins', {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            console.log(r);
            // if (r.status === 200) {
            //     resolve(r);
            // } else {
            //     reject(r);
            // }
        });
    }));
}

export const getCurrentBusiness = (id) => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        console.log('business token: ' + Store.get('accessToken').token);
        Axios.get(process.env.REACT_APP_API_BASE + '/business/businesses', {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                Store.set('user', r.data);
                resolve(r.data);
            }
        });
    }));
};