import React from "react";
import {Link} from "react-router-dom";
import Style from "./User.module.scss";
import moment from "moment";

class User extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.displayDate = this.displayDate.bind(this);
    }

    displayDate(time) {
        if (!time) return false;

        return moment(time).format('MM DD YYYY');
    }

    render() {
        return (
            <Link className={Style.item} to={"/dashboard/users/edit/"+this.props.entry.id}>
                <div className="card no-shadow">
                    <div className="card-header border-0">
                        <div className="meta-wrapper d-flex justify-content-between align-items-center">
                            <span className="strong mr-1 d-block">Email: {this.props.entry.email}</span>
                            <span className="strong d-block">Role: {this.props.entry.role}</span>
                        </div>
                    </div>
                    <div className="card-body bg-white pt-0">
                        <h4 className={"mb-0"}>{this.props.entry.name}</h4>
                    </div>
                </div>
            </Link>
        );
    }
}

User.propTypes = {};

export default User;
