import React from "react";
import {
    Link,
    withRouter
} from "react-router-dom";
import Store from "store";
import Axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {getCurrentUser} from "../../../../utils/utils";


class EditUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.entryId,
            token: Store.get('accessToken').token,
            name: '',
            email: '',
            role: '',
            password1: '',
            password2: ''
        };

        if (this.state.token && this.state.id) {
            Axios.get(process.env.REACT_APP_API_BASE + '/mgmt/users/'+this.state.id, {
                headers: {
                    'Authorization': this.state.token
                },
            } ).then(response => {
                if (response.status === 200) {
                    this.setState({
                        name: response.data.name,
                        email: response.data.email,
                        role: response.data.role,
                    });
                }
            }).catch(error => {
                console.log('error', error);
            });
        }


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.doUpdateUser = this.doUpdateUser.bind(this);
        this.doUpdatePassword = this.doUpdatePassword.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.state.email || !this.state.name) {
            this.setState({
                'formErrors': 'Missing fields'
            });

            return null;
        }
        else if (this.state.password1 !== this.state.password2) {
            this.setState({
                'formErrors': 'Passwords don\'t match'
            });

            return null;
        }
        else {
            this.setState({
                'formErrors': ''
            });
        }


        //If user has set passwords, perform password update first, then move to doUpdateUser
        if (this.state.password1 && this.state.password2) {
            this.doUpdatePassword().then(resp => {
                console.log('successfully updated password', resp);
                this.doUpdateUser();
            }).catch(error => {
                console.log('an error occurred', error);
            });
        } else {
            this.doUpdateUser();
        }

    }

    //Performs user update
    doUpdateUser() {
        let formData = {
            'enabled':  true,
            'name': this.state.name,
            'role': this.state.role,
        };
        Axios.put(process.env.REACT_APP_API_BASE + '/mgmt/users/'+this.state.id, formData, {
            headers: {
                'Authorization': Store.get('accessToken').token
            }
        } )
            .then(response => {
                if (response.status === 204) {
                    getCurrentUser().then(() => {
                        this.props.userUpdate();
                        this.props.history.push('/dashboard/users');
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    'formErrors': error.response.data.message
                })
            })
    }

    //Updates password for user in separate API call
    doUpdatePassword() {
        return new Promise((resolve, reject) => {
            if (this.state.password1 && this.state.password2 && (this.state.password1 === this.state.password2)) {
                let formData = {
                    'password1': this.state.password1,
                    'password2': this.state.password2
                };
                Axios.post(process.env.REACT_APP_API_BASE + '/mgmt/users/'+this.state.id+'/change-password', formData, {
                    headers: {
                        'Authorization': Store.get('accessToken').token
                    }
                } )
                    .then(response => {
                        if (response.status === 204) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    })
                    .catch(error => {
                        reject(error);
                        this.setState({
                            'formErrors': error.response.data.message
                        })
                    })
            } else {
                reject('Passwords don\'t match or have not been set properly.')
            }
        });
    }

    handleChange(e) {
        //Sets state with the appropriate key, to the matching input's value
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/users/"} className={"btn-min"}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span className="ml-1">View all Users</span>
                        </Link>
                        <h1 className={"mt-1"}>Editing user: {this.state.name ? this.state.name : null}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-7">
                        <div className=" no-shadow no-border">
                            <div className="row">
                                <div className="col-12">
                                    <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input type="email" className={"form-control"} name={"email"} id={"email"} value={this.state.email} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name">Role</label>
                                            <input type="text" className={"form-control"} name={"role"} id={"role"} value={this.state.role} disabled />
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label htmlFor="name">Name*</label>
                                                    <input type="text" placeholder={"John"} className={"form-control"} name={"name"} id={"name"} value={this.state.name} onChange={this.handleChange} required />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="password1">Password</label>
                                                    <input type="password" className={"form-control"} name={"password1"} id={"password1"} value={this.state.password1} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="password2">Password Confirmation</label>
                                                    <input type="password" className={"form-control"} name={"password2"} id={"password2"} value={this.state.password2} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="errors">
                                                    <h6 className={"mb-0"}>{this.state.formErrors}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EditUser.propTypes = {};

export default withRouter(EditUser);
