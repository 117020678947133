import React from "react";
import {Link} from "react-router-dom";
import {getAdmins} from "../../../../utils/utils";
import Axios from "axios";
import Select from 'react-select';

import Store from "store";
import {config, parse} from "@fortawesome/fontawesome-svg-core";

class AddNewShipRelations extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            id: this.props.match.params.entryId,
            items: [],
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const id = parseInt(e.target.id);
        const value = e.target.value;
        let findIndex = this.state.items.findIndex(item => {
            return item.id === id
        });
        let items = [...this.state.items];
        let item = {...this.state.items[findIndex]};
        item.trip_time = value;
        items[findIndex] = item;
        this.setState({
            items: items
        })
    }

    componentDidMount() {
        let config = {};
        config.headers = {
            'Authorization': 'Bearer ' + this.state.token
        };

        Axios.get(process.env.REACT_APP_API_BASE + '/business/ship/' + this.state.id + '/relations', config)
        .then(response => {
            if(response.status === 200) {
                this.setState({
                    items: response.data
                })

                let items = [...this.state.items];
                items.forEach(item => {
                    let position = items.findIndex(pos => {
                        return pos.id === item.id
                    })

                    let itemPos = {...this.state.items[position]};

                    if(itemPos.trip_time) {
                        let timeArr = itemPos.trip_time.split(':');
                        let hours = parseInt(timeArr[0]);
                        let minutes = parseInt(timeArr[1]);

                        let total_minutes = hours * 60 + minutes;



                        itemPos.trip_time = total_minutes;
                        items[position] = itemPos;
                    }

                })

                this.setState({
                    items: items
                })
            }
        })
        getAdmins();
    }

    handleSubmit(e) {
        e.preventDefault();

        let config = {};
        config.headers = {
            'Authorization': 'Bearer ' + this.state.token
        };

        Axios.post(process.env.REACT_APP_API_BASE + '/business/ship/' + this.state.id + '/relations', this.state.items, config)
        .then(response => {
            if(response.status === 200) {
                this.props.history.push('/dashboard/ships/ship/' + this.state.id);
            }
        })
    }

    render() {
        const relations = this.state.items.map(item => {
            return (
                <tr>
                    <td>{item.departure_name}</td>
                    <td>{item.destination_name}</td>
                    <td><input type="text" placeholder="minute" className={"form-control"} id={item.id} name={"relation_" + item.id} value={item.trip_time} onChange={this.handleChange} /></td>
                </tr>
            )
        })
        return (
            <div className="container pb-10" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard"} className={"btn-min"}>
                            <span className="ml-1">Preglej plovila in kapetane</span>
                        </Link>
                        <h1 className={"mt-1"}>Urejanje relacij</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>
                            <button type="submit" className="btn btn-secondary" onClick={this.handleSubmit}>Shrani spremembe</button>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">Odhod</th>
                                    <th scope="col">Prihod</th>
                                    <th scope="col">Čas potovanja</th>
                                </tr>
                                </thead>
                                <tbody>
                                {relations}
                                </tbody>
                            </table>

                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

AddNewShipRelations.propTypes = {};

export default AddNewShipRelations;
